import { default as about_45us4quh9sPYskMeta } from "/app/src/pages/about-us.vue?macro=true";
import { default as accept_45termsgwagLyZS3oMeta } from "/app/src/pages/accept-terms.vue?macro=true";
import { default as _91token_93Nyb2EPRkbYMeta } from "/app/src/pages/activate/[token].vue?macro=true";
import { default as adminKPGIlZeS47Meta } from "/app/src/pages/admin.vue?macro=true";
import { default as createbasketJ4GnzL1phGMeta } from "/app/src/pages/assets/createbasket.vue?macro=true";
import { default as indexsuR7E7oGYBMeta } from "/app/src/pages/assets/index.vue?macro=true";
import { default as post2f7ERy2arMMeta } from "/app/src/pages/assets/post.vue?macro=true";
import { default as retiretOcxIO0EoIMeta } from "/app/src/pages/assets/retire.vue?macro=true";
import { default as transferF7rl0EQpt1Meta } from "/app/src/pages/assets/transfer.vue?macro=true";
import { default as update_45basket_45_91basketId_93nPj8ybDVTtMeta } from "/app/src/pages/assets/update-basket-[basketId].vue?macro=true";
import { default as dashboardLd3YXvnTkBMeta } from "/app/src/pages/dashboard.vue?macro=true";
import { default as forgot_45passwordvHMplVNcGwMeta } from "/app/src/pages/forgot-password.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as loading0LEIuDX6KZMeta } from "/app/src/pages/loading.vue?macro=true";
import { default as indexsTxGzTIguDMeta } from "/app/src/pages/mapper/index.vue?macro=true";
import { default as pending_45transfers0QFvtiyq0CMeta } from "/app/src/pages/pending-transfers.vue?macro=true";
import { default as all_45posted_45assetsuTh26r9KA8Meta } from "/app/src/pages/posts/all-posted-assets.vue?macro=true";
import { default as my_45postspEFNQxyuOAMeta } from "/app/src/pages/posts/my-posts.vue?macro=true";
import { default as editqGTiTNmkzCMeta } from "/app/src/pages/profile/edit.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as _91projectId_93SruFcGk1xOMeta } from "/app/src/pages/projects/[projectId].vue?macro=true";
import { default as create_45project_45apiD3vthBCK14Meta } from "/app/src/pages/projects/create-project-api.vue?macro=true";
import { default as createAmnvkgzS0kMeta } from "/app/src/pages/projects/create.vue?macro=true";
import { default as _91projectId_936OkcWqmf0iMeta } from "/app/src/pages/projects/edit/[projectId].vue?macro=true";
import { default as indexCBid7U1lDFMeta } from "/app/src/pages/projects/index.vue?macro=true";
import { default as _91projectId_93uuDEZNzc2WMeta } from "/app/src/pages/projects/reportings/[projectId].vue?macro=true";
import { default as view_45_91reportingId_93WyA2EQDWsBMeta } from "/app/src/pages/projects/reportings/view-[reportingId].vue?macro=true";
import { default as _91projectId_93lQOyf7Z00PMeta } from "/app/src/pages/projects/verification/[projectId].vue?macro=true";
import { default as _91projectId_930cmNLAs0Y3Meta } from "/app/src/pages/projects/view/[projectId].vue?macro=true";
import { default as _91realmId_93KoHXbmhNs9Meta } from "/app/src/pages/realms/[realmId].vue?macro=true";
import { default as createkYBvghTsU9Meta } from "/app/src/pages/realms/create.vue?macro=true";
import { default as indexCvaUtypjjJMeta } from "/app/src/pages/realms/index.vue?macro=true";
import { default as requestsmGuHUGUXZcMeta } from "/app/src/pages/requests.vue?macro=true";
import { default as retirement_45historyK7I2JITdJ0Meta } from "/app/src/pages/retirement-history.vue?macro=true";
import { default as _91roleId_9330ppyhG9aEMeta } from "/app/src/pages/roles/[roleId].vue?macro=true";
import { default as _91roleId_93dG4zb9ccdvMeta } from "/app/src/pages/roles/assign-permission/[roleId].vue?macro=true";
import { default as createXLuhXWGZiFMeta } from "/app/src/pages/roles/create.vue?macro=true";
import { default as indexkJg5YrUO75Meta } from "/app/src/pages/roles/index.vue?macro=true";
import { default as settings4uvkikcWGMMeta } from "/app/src/pages/settings.vue?macro=true";
import { default as terms_45of_45useDLhNEDqteIMeta } from "/app/src/pages/terms-of-use.vue?macro=true";
import { default as transfer_45historygQQFWOH2ZAMeta } from "/app/src/pages/transfer-history.vue?macro=true";
import { default as user_45guideJgeGwj2OCkMeta } from "/app/src/pages/user-guide.vue?macro=true";
import { default as _91userId_93EzuoH4WY0KMeta } from "/app/src/pages/users/[userId].vue?macro=true";
import { default as create1OYTP8mgMeMeta } from "/app/src/pages/users/create.vue?macro=true";
import { default as indexjNRiaiFqzBMeta } from "/app/src/pages/users/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45us4quh9sPYskMeta || {},
    component: () => import("/app/src/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "accept-terms",
    path: "/accept-terms",
    meta: accept_45termsgwagLyZS3oMeta || {},
    component: () => import("/app/src/pages/accept-terms.vue").then(m => m.default || m)
  },
  {
    name: "activate-token",
    path: "/activate/:token()",
    meta: _91token_93Nyb2EPRkbYMeta || {},
    component: () => import("/app/src/pages/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminKPGIlZeS47Meta || {},
    component: () => import("/app/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "assets-createbasket",
    path: "/assets/createbasket",
    meta: createbasketJ4GnzL1phGMeta || {},
    component: () => import("/app/src/pages/assets/createbasket.vue").then(m => m.default || m)
  },
  {
    name: "assets",
    path: "/assets",
    meta: indexsuR7E7oGYBMeta || {},
    component: () => import("/app/src/pages/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "assets-post",
    path: "/assets/post",
    meta: post2f7ERy2arMMeta || {},
    component: () => import("/app/src/pages/assets/post.vue").then(m => m.default || m)
  },
  {
    name: "assets-retire",
    path: "/assets/retire",
    meta: retiretOcxIO0EoIMeta || {},
    component: () => import("/app/src/pages/assets/retire.vue").then(m => m.default || m)
  },
  {
    name: "assets-transfer",
    path: "/assets/transfer",
    meta: transferF7rl0EQpt1Meta || {},
    component: () => import("/app/src/pages/assets/transfer.vue").then(m => m.default || m)
  },
  {
    name: "assets-update-basket-basketId",
    path: "/assets/update-basket-:basketId()",
    meta: update_45basket_45_91basketId_93nPj8ybDVTtMeta || {},
    component: () => import("/app/src/pages/assets/update-basket-[basketId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardLd3YXvnTkBMeta || {},
    component: () => import("/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordvHMplVNcGwMeta || {},
    component: () => import("/app/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "loading",
    path: "/loading",
    meta: loading0LEIuDX6KZMeta || {},
    component: () => import("/app/src/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: "mapper",
    path: "/mapper",
    meta: indexsTxGzTIguDMeta || {},
    component: () => import("/app/src/pages/mapper/index.vue").then(m => m.default || m)
  },
  {
    name: "pending-transfers",
    path: "/pending-transfers",
    meta: pending_45transfers0QFvtiyq0CMeta || {},
    component: () => import("/app/src/pages/pending-transfers.vue").then(m => m.default || m)
  },
  {
    name: "posts-all-posted-assets",
    path: "/posts/all-posted-assets",
    meta: all_45posted_45assetsuTh26r9KA8Meta || {},
    component: () => import("/app/src/pages/posts/all-posted-assets.vue").then(m => m.default || m)
  },
  {
    name: "posts-my-posts",
    path: "/posts/my-posts",
    meta: my_45postspEFNQxyuOAMeta || {},
    component: () => import("/app/src/pages/posts/my-posts.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editqGTiTNmkzCMeta || {},
    component: () => import("/app/src/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexSJNeMkG0qzMeta || {},
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-projectId",
    path: "/projects/:projectId()",
    meta: _91projectId_93SruFcGk1xOMeta || {},
    component: () => import("/app/src/pages/projects/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "projects-create-project-api",
    path: "/projects/create-project-api",
    meta: create_45project_45apiD3vthBCK14Meta || {},
    component: () => import("/app/src/pages/projects/create-project-api.vue").then(m => m.default || m)
  },
  {
    name: "projects-create",
    path: "/projects/create",
    meta: createAmnvkgzS0kMeta || {},
    component: () => import("/app/src/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: "projects-edit-projectId",
    path: "/projects/edit/:projectId()",
    meta: _91projectId_936OkcWqmf0iMeta || {},
    component: () => import("/app/src/pages/projects/edit/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexCBid7U1lDFMeta || {},
    component: () => import("/app/src/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-reportings-projectId",
    path: "/projects/reportings/:projectId()",
    meta: _91projectId_93uuDEZNzc2WMeta || {},
    component: () => import("/app/src/pages/projects/reportings/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "projects-reportings-view-reportingId",
    path: "/projects/reportings/view-:reportingId()",
    meta: view_45_91reportingId_93WyA2EQDWsBMeta || {},
    component: () => import("/app/src/pages/projects/reportings/view-[reportingId].vue").then(m => m.default || m)
  },
  {
    name: "projects-verification-projectId",
    path: "/projects/verification/:projectId()",
    meta: _91projectId_93lQOyf7Z00PMeta || {},
    component: () => import("/app/src/pages/projects/verification/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "projects-view-projectId",
    path: "/projects/view/:projectId()",
    meta: _91projectId_930cmNLAs0Y3Meta || {},
    component: () => import("/app/src/pages/projects/view/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "realms-realmId",
    path: "/realms/:realmId()",
    meta: _91realmId_93KoHXbmhNs9Meta || {},
    component: () => import("/app/src/pages/realms/[realmId].vue").then(m => m.default || m)
  },
  {
    name: "realms-create",
    path: "/realms/create",
    meta: createkYBvghTsU9Meta || {},
    component: () => import("/app/src/pages/realms/create.vue").then(m => m.default || m)
  },
  {
    name: "realms",
    path: "/realms",
    meta: indexCvaUtypjjJMeta || {},
    component: () => import("/app/src/pages/realms/index.vue").then(m => m.default || m)
  },
  {
    name: "requests",
    path: "/requests",
    meta: requestsmGuHUGUXZcMeta || {},
    component: () => import("/app/src/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: "retirement-history",
    path: "/retirement-history",
    meta: retirement_45historyK7I2JITdJ0Meta || {},
    component: () => import("/app/src/pages/retirement-history.vue").then(m => m.default || m)
  },
  {
    name: "roles-roleId",
    path: "/roles/:roleId()",
    meta: _91roleId_9330ppyhG9aEMeta || {},
    component: () => import("/app/src/pages/roles/[roleId].vue").then(m => m.default || m)
  },
  {
    name: "roles-assign-permission-roleId",
    path: "/roles/assign-permission/:roleId()",
    meta: _91roleId_93dG4zb9ccdvMeta || {},
    component: () => import("/app/src/pages/roles/assign-permission/[roleId].vue").then(m => m.default || m)
  },
  {
    name: "roles-create",
    path: "/roles/create",
    meta: createXLuhXWGZiFMeta || {},
    component: () => import("/app/src/pages/roles/create.vue").then(m => m.default || m)
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexkJg5YrUO75Meta || {},
    component: () => import("/app/src/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings4uvkikcWGMMeta || {},
    component: () => import("/app/src/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45useDLhNEDqteIMeta || {},
    component: () => import("/app/src/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "transfer-history",
    path: "/transfer-history",
    meta: transfer_45historygQQFWOH2ZAMeta || {},
    component: () => import("/app/src/pages/transfer-history.vue").then(m => m.default || m)
  },
  {
    name: "user-guide",
    path: "/user-guide",
    meta: user_45guideJgeGwj2OCkMeta || {},
    component: () => import("/app/src/pages/user-guide.vue").then(m => m.default || m)
  },
  {
    name: "users-userId",
    path: "/users/:userId()",
    meta: _91userId_93EzuoH4WY0KMeta || {},
    component: () => import("/app/src/pages/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: create1OYTP8mgMeMeta || {},
    component: () => import("/app/src/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexjNRiaiFqzBMeta || {},
    component: () => import("/app/src/pages/users/index.vue").then(m => m.default || m)
  }
]