import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import * as Asset from '~/static/urls/assets';
import * as Retire from '~/static/urls/retireHistory';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { AssetInventory } from '@/models/AssetInventory';
import { assetFilterObj, getQueryString } from '@/utils/config';
import { API_REALM_LIST } from '~/static/urls/transferHistory';
import useAssetCol from '@/composables/useAssetCol';
import useStringSorting from '~/composables/useStringSorting.js';
import { useCloneDeep } from '#imports';

const useAssetStore = defineStore(
  'assetStore',
  () => {
    const assetData = ref(null);
    const assetCart = ref([]);
    const retireAsset = ref({});
    const companyList = ref([]);
    const successMessage = ref(null);
    const clearObj = useClearFetchStore();
    const basketDetail = ref({});
    const assetFilter = ref(assetFilterObj());
    const queryParams = ref({});
    const retireResp = ref(null);
    const transferResp = ref(null);
    const postResp = ref(null);
    const isCreateRetireAPIFailed = ref(false);
    const retireState = ref({reasonForRetire: '', emails: ''});

    const getTransferResp = computed(() => transferResp.value);
    const getPostResp = computed(() => postResp.value);
    const setAsset = (assetValue) => {
      assetData.value = assetValue;
    };
    const setRetireState = (stateKey, stateValue) => {
      retireState.value[stateKey] = stateValue;
    }
    const getRetireState = computed(() => retireState.value);
    const clearRetireState = () => {
      retireState.value = {reasonForRetire: '', emails: ''};
    }
    const addToCart = (selectedAsset) => {
      assetCart.value = selectedAsset;
    };

    const setRetireResp = (resp) => (retireResp.value = resp);

    const addToRetireAsset = (payload) => {
      retireAsset.value = payload;
    };

    const updateSuccess = (msg) => {
      successMessage.value = msg;
    };

    const setAssetFilter = (filters) => {
      assetFilter.value = filters;
    };
    const formatAssetList = (lists, assetSource) => {
      return lists.map((aRow) => {
        const newRow = useAssetCol(aRow);
        if (
          assetCart.value
            ?.map((cartItem) => `${cartItem.id.title}-${cartItem.type.title}`)
            ?.includes(`${newRow.id.title}-${newRow.type.title}`)
        ) {
          if (newRow?.isChecked && typeof newRow.isChecked === 'object') {
            newRow.isChecked.title = true;
          } else {
            newRow.isChecked = { title: true };
          }
        }
        return { ...newRow, asset_source: assetSource };
      });
    };
    const getAssetInventory = async (row) => {
      const rowIndex = assetData?.value?.list?.findIndex(
        (rw) => rw.id.title === row.id.title && rw.type.title === row.type.title
      );
      assetData.value.list[rowIndex].isActive = !assetData.value.list[rowIndex].isActive;
      if ( assetData.value.list[rowIndex].isActive || row?.isActive === undefined) {
        const query = getQueryString({
          assetsFilters: assetFilter.value
        });
        clearObj.disablePendingState(true);

        const childrenURL = `${Asset.API_GET_CHILDREN_URL.replace('{projectId}', row.id.title).replace(
          '{projectType}',
          row.type.title
        )}?${query.join('&')}`;
        await clearObj.clearGet(childrenURL);
        clearObj.disablePendingState(false);

        if (clearObj.storeData) {
          let invList = [];
          if (clearObj.storeData.data.list) {
            invList = formatAssetList(clearObj.storeData.data.list, assetData.value.list[rowIndex]['asset_source']);
          }
          assetData.value.list[rowIndex].children = invList;
        }
      }
    };

    const resetAssetInventorySelection = () => {
      assetData.value.list = assetData.value.list?.map((list) => {
        return { ...list, isActive: false };
      });
    };

    // handle query params
    const handleExportCols = (cols) => {
      queryParams.value = { ...queryParams.value, exportColumn: cols };
    };

    const handleList = async (assetRequestData) => {
      queryParams.value = { ...assetRequestData, assetsFilters: JSON.stringify(assetRequestData.assetsFilters) };

      const assetRequestDataClone = useCloneDeep(assetRequestData);
      if (assetRequestDataClone.exportColumn) {
        delete assetRequestDataClone.exportColumn;
      }

      const query = getQueryString(assetRequestDataClone);

      await clearObj.clearGet(`${Asset.API_GET_URL}?${query.join('&')}`);

      if (clearObj.storeData) {
        assetData.value = clearObj.storeData.data;
      }
    };
    const getBasketDetailById = async (basketId) => {
      const { data } = await clearObj.clearGet(Asset.API_VIEW_BASKET.replace('{basketId}', basketId), true);

      if (data?.value?.data) {
        basketDetail.value = data?.value?.data;
      }
    };

    const getCompanyList = async () => {
      await clearObj.clearGet(`${API_REALM_LIST}`);
      if (clearObj.storeData) {
        companyList.value = clearObj.storeData.data;
      }
    };

    const handleCreateRetireAsset = async (req) => {
      clearObj.disablePendingState(false);
      clearObj.setApiNotification(false);
      await clearObj.clearPost(`${Retire.API_POST_URL}`, req);
      if (clearObj.storeData) {
        setRetireResp(clearObj.storeData.data.retired);
        isCreateRetireAPIFailed.value = false;
        clearRetireState();
      } else{
        if ([400,422,500].includes(clearObj.getError.status)) {
          clearObj.showToastMessage(clearObj.getError.message);
        }
        isCreateRetireAPIFailed.value = true;
      }
      clearObj.setApiNotification(true);
    };

    // Create Basket
    const handleCreateBasket = async (req) => {
      clearObj.setApiNotification(false);
      await clearObj.clearPost(`${Asset.API_CREATE_BASKET}`, req);
      clearObj.setApiNotification(true);
    };
    // Updated Basket
    const handleUpdateBasket = async (req, id) => {
      clearObj.setApiNotification(false);
      await clearObj.clearPut(`${Asset.API_UPDATE_BASKET.replace('{basketId}', id)}`, req);
      clearObj.setApiNotification(true);
    };

    // Create Post
    const handleCreateAssetPost = async (req) => {
      clearObj.disablePendingState(false);
      clearObj.setApiNotification(false);
      postResp.value = null;
      const resp = await clearObj.clearPost(`${Asset.API_CREATE_POST}`, req, true);
      if (resp.error && resp.error?.value?.data?.data) {
        postResp.value = resp.error?.value?.data?.data;
        clearObj.showToastMessage(resp.error.value.data.data);
      } else {
        clearObj.setApiNotification(true);
        return Promise.resolve(true);
      }
    };

    // Post API call for Transfer
    const handlePostInitiateTransfer = async (req) => {
      clearObj.disablePendingState(false);
      // clearObj.setApiNotification(false);
      transferResp.value = null;
      const resp = await clearObj.clearPost(`${Asset.API_POST_INITIATE_TRNSFR}`, req, true);
      if (resp.error && resp.error?.value?.data?.data) {
        transferResp.value = resp.error?.value?.data?.data;
        clearObj.showToastMessage(resp.error.value.data.data);
      } else {
        // clearObj.setApiNotification(true);
        return Promise.resolve(true);
      }

    };

    const getErrorObj = computed(() => clearObj.getError);

    const getError = computed(() => clearObj.error);
    const isRetireAPIFailed = computed(() => isCreateRetireAPIFailed.value);

    const getRetiredResp = computed(() => retireResp.value);

    const resetState = () => {
      setAsset([]);
    };
    const resetAssetCart = () => {
      assetCart.value = [];
      assetData.value?.list?.forEach((element) => {
        if (element?.isChecked?.title) {
          element.isChecked.title = false;
        }
        if (element?.isActive) {
          element.isActive = false;
        }
      });
    };

    const getListing = computed(() => {
      if (!assetData.value) {
        return new AssetInventory([]);
      }
      return new AssetInventory(assetData.value.list);
    });

    const getFrom = computed(() => {
      if (!assetData.value) {
        return 0;
      }
      return assetData.value.from;
    });

    const getTo = computed(() => {
      if (!assetData.value) {
        return 0;
      }
      return assetData.value.to;
    });

    const getCurrentPage = computed(() => {
      if (!assetData.value) {
        return 0;
      }
      return assetData.value.current_page;
    });

    const getLastPage = computed(() => {
      if (!assetData.value) {
        return 0;
      }
      return assetData.value.lastPage;
    });

    const getTotal = computed(() => {
      if (!assetData.value) {
        return 0;
      }
      return assetData.value.total;
    });
    const isPending = computed(() => clearObj.pending);
    const setErrorNull = () => {
      clearObj.setErrorNull();
    };

    const clearAssetData = () => {
      assetData.value = null;
      assetCart.value = [];
      retireAsset.value = {};
      companyList.value = [];
      successMessage.value = null;
      basketDetail.value = {};
      assetFilter.value = assetFilterObj();
      retireResp.value = null;
      queryParams.value = {};
    };

    const getBasketDetail = computed(() => basketDetail.value);

    const getCompanies = computed(() => useStringSorting(companyList.value, 'name'));

    return {
      // state
      assetData,
      assetCart,
      retireAsset,
      successMessage,
      companyList,
      basketDetail,
      assetFilter,
      queryParams,

      // actions
      handleList,
      handleCreateRetireAsset,
      handlePostInitiateTransfer,
      handleCreateAssetPost,
      handleCreateBasket,
      resetState,
      updateSuccess,
      setAsset,
      addToCart,
      addToRetireAsset,
      setErrorNull,
      resetAssetCart,
      getCompanyList,
      getBasketDetailById,
      handleUpdateBasket,
      setAssetFilter,
      setRetireResp,
      getAssetInventory,
      clearAssetData,
      handleExportCols,
      resetAssetInventorySelection,
      setRetireState,
      clearRetireState,

      // getters
      getListing,
      getFrom,
      getTo,
      getCurrentPage,
      getLastPage,
      getTotal,
      getErrorObj,
      getError,
      isPending,
      getBasketDetail,
      getRetiredResp,
      getCompanies,
      isRetireAPIFailed,
      getTransferResp,
      getPostResp,
      getRetireState
    };
  },
  {
    persist: false
  }
);

export default useAssetStore;
