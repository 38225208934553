import { computed, ref, watchEffect } from 'vue';
import { navigateTo, useFetch, useNuxtApp, useRuntimeConfig } from '#app';
import { defineStore } from 'pinia';
import * as Methods from '~/static/urls/methods.js';

const useClearFetchStore = defineStore(
  'clearFetch',
  () => {
    const storeData = ref(null);
    const error = ref(null);
    const status = ref(null);
    const message = ref('');
    const pending = ref(false);
    const isLoading = ref(false);
    const disablePending = ref(false);
    const loginData = ref(null);
    const lastActivityLog = ref(null);
    const isApiNotification = ref(true); // used to disable notification for any api
    const errorHandling = ref(null);

    const runtimeConfig = useRuntimeConfig();

    const updatePendingState = (state) => {
      pending.value = state;
    };

    /**
     * @method: method to update the loading status to show  loader on UI
     * @param {*} state
     */
    const updateLoadingState = (state) => {
      isLoading.value = state;
    };
    const disablePendingState = (state) => {
      disablePending.value = state;
    };

    const updateErrorState = (state) => {
      error.value = state;
    };

    const updateMessage = (msg) => {
      message.value = msg;
    };

    const setLastActivity = (activityCode) => {
      lastActivityLog.value = activityCode;
    };

    const showToastMessage = (msg, isSuccess = false) => {
      if (process.client && !isSuccess) {
        useNuxtApp().$toast.error(msg, { autoClose: 5000 });
      } else if (process.client && isSuccess) {
        useNuxtApp().$toast.success(msg, { autoClose: 5000 });
      }
    };
    const setApiNotification = (notification) => (isApiNotification.value = notification);

    const updateStates = () => {
      storeData.value = null;
      status.value = null;
      if (!disablePending.value) {
        updatePendingState(true);
      } else {
        updatePendingState(false);
      }
      updateErrorState(false);
      updateMessage('');
    };

    const getHeaders = computed(() => {
      const headers = {
        ContentType: 'application/json',
        Accept: 'application/json'
      };
      if (getAccessToken.value) {
        headers.Authorization = `Bearer ${getAccessToken.value}`;
      }
      return headers;
    });

    const getAuthorizationHeaders = computed(() => {
      const headers = {};
      if (getAccessToken.value) {
        headers.Authorization = `Bearer ${getAccessToken.value}`;
      }
      return headers;
    });

    const getError = computed(() => {
      return {
        status: status.value,
        error: error.value,
        message: message.value
      };
    });
    const isPending = computed(() => pending.value);

    const setLoginData = (login) => {
      loginData.value = login;
    };

    const getAccessToken = computed(() => {
      if (!loginData.value) {
        return false;
      }
      const now = new Date();
      if (now.getTime() > loginData.value.expires_in) {
        setLoginData(null);
        return false;
      }
      return loginData.value.access_token;
    });

    const setErrorNull = () => {
      error.value = null;
      status.value = 200;
      message.value = null;
    };

    const updateErrorAndStoreVar = (storeObj, isNotification = false) => {
      if (storeObj.error.value) {
        status.value = storeObj.error.value.statusCode;
        if (process.client && [400, 422, 500].includes(status.value)) {
          updateMessage(
            status.value && [400, 422,500].includes(status.value)
              ? (storeObj?.error?.value?.data?.data || storeObj?.error?.value?.data?.message)
              : storeObj?.error?.value?.data?.message
          );
        } else {
          updateMessage(storeObj?.error?.value?.data?.message);
        }

        updateErrorState(true);
      } else {
        if (storeObj.data.value) {
          updateMessage(storeObj.data.value.message);
          if (isNotification && isApiNotification.value && process.client) {
            showToastMessage(storeObj.data.value.message, true);
          }
        }
        status.value = 200;
        storeData.value = storeObj.data.value;
      }
      updatePendingState(false);
    };

    const handleErrorStatus = (fetchObj, isReturn = false) => {
      if (fetchObj.error) {
        errorHandling.value = fetchObj.error;
      }
      if (isReturn) {
        updatePendingState(false);
        if (fetchObj?.error?.value) {
          status.value = fetchObj?.error?.value?.statusCode;
        } else {
          status.value = fetchObj?.data?.value?.status;
        }
      }
    };

    const clearGet = async (url, isReturn = false, request) => {
      updateStates();
      const fetchObj = await useFetch(() => url, {
        baseURL: runtimeConfig.public.apiBase,
        credentials: 'include',
        headers: getHeaders.value,
        params: request
      });
      handleErrorStatus(fetchObj, isReturn);
      if (isReturn) {
        return fetchObj;
      }
      updateErrorAndStoreVar(fetchObj);
    };

    const clearPost = async (postURL, postData, isReturn = false) => {
      updateStates();
      const fetchObj = await useFetch(() => postURL, {
        baseURL: runtimeConfig.public.apiBase,
        headers: getHeaders.value,
        credentials: 'include',
        method: Methods.POST,
        body: JSON.stringify(postData)
      });
      handleErrorStatus(fetchObj, isReturn);
      if (isReturn) {
        return fetchObj;
      }
      updateErrorAndStoreVar(fetchObj, true);
    };

    const clearPut = async (putURL, putData, isReturn = false) => {
      updateStates();
      const fetchObj = await useFetch(() => putURL, {
        baseURL: runtimeConfig.public.apiBase,
        headers: getHeaders.value,
        method: Methods.PUT,
        credentials: 'include',
        body: JSON.stringify(putData)
      });
      handleErrorStatus(fetchObj, isReturn);
      if (isReturn) {
        return fetchObj;
      }
      updateErrorAndStoreVar(fetchObj, true);
    };

    const clearDelete = async (deleteURL, isReturn = false) => {
      updateStates();
      const fetchObj = await useFetch(() => deleteURL, {
        baseURL: runtimeConfig.public.apiBase,
        headers: getHeaders.value,
        credentials: 'include',
        method: Methods.DELETE
      });
      handleErrorStatus(fetchObj, isReturn);
      if (isReturn) {
        return fetchObj;
      }
      updateErrorAndStoreVar(fetchObj, true);
    };

    const clearData = () => {
      storeData.value = null;
      error.value = null;
      message.value = '';
      pending.value = false;
      disablePending.value = false;
      loginData.value = null;
      lastActivityLog.value = null;
      isApiNotification.value = true;
    };
    const getStatus = computed(() => status.value);

    watchEffect(
      () => {
        if (errorHandling.value?.value?.statusCode === 403) {
          showToastMessage(errorHandling.value?.value?.data?.data);
          errorHandling.value = null;
          const t = setTimeout(() => {
            clearTimeout(t);
            if (process.client && useNuxtApp().$ability.can('list', 'Dashboard')) {
              navigateTo('/dashboard');
            } else {
              navigateTo('/projects');
            }
          }, 2000);
        }
      },
      {
        flush: 'post'
      }
    );

    return {
      setLoginData,
      clearGet,
      clearPost,
      clearPut,
      clearDelete,
      updateMessage,
      setErrorNull,
      setLastActivity,
      setApiNotification,
      showToastMessage,
      disablePendingState,
      updatePendingState,
      updateLoadingState,
      clearData,

      getAccessToken,
      getHeaders,
      getAuthorizationHeaders,
      getError,
      loginData,
      storeData,
      isApiNotification,
      pending,
      error,
      status,
      message,
      lastActivityLog,
      getStatus,
      isPending,
      isLoading
    };
  },
  {
    persist: true
  }
);

export default useClearFetchStore;
